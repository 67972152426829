import { Grid, Typography, Card, CardContent, CardActions, Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

const GetUsKnow = () => {

    const navigate = useNavigate();

    const handleNavigate = (pValue) => {
        navigate(`/${pValue}`)
    }

    return (
        <Grid item sx={{px: isMobile ? 2.5 : 22.5, pt: !isMobile ? 10 : 7.5, width:'100%', maxWidth:1440}}>
            <Typography className='titleStyle title-color' sx={{pb:2}}>Get to know us</Typography>
            <Grid item className="aboutus-get-subdiv" sx={{background: !isMobile && 'url(/assets/images/abuotgdetbg.png) no-repeat', backgroundPosition:'center right'}}> 
                <Grid item xs={12} sm={4} md={5}>
                    <Typography variant='body2' component={'p'} className='normal-text size-20'>
                        OUR APPROACH
                    </Typography>
                    <Card sx={{mt:'1.2em', mb:'2em', borderRadius:0 }}>
                        <div style={{position:'relative', height:220}}>
                            <div className='img-wrapper'>
                                <img src='/assets/images/getimage1.jpg' alt='Blog' style={{width:'100%', height: isMobile && '100%'}}/>
                            </div>
                        </div>
                    </Card>
                    <CardContent sx={{pl:0, py:0, pr: isMobile && 0}}>
                        <Typography variant='h4' component={'h4'} sx={{minHeight:'3em', mb:2, fontFamily: 'Playfair Display',fontSize:'1.5em',fontWeight:500, lineHeight:'150%'}}>
                            Step into a space alive with the power of 'prana' - the life force
                        </Typography>
                        <Typography variant='body2' component={'p'} className='normal-text' sx={{minHeight:150}}>
                            With every project, Unique Engineers redefines what's possible in the world of MEP solutions. Keeping ethics, environment, the life force or 'prana' at the heart of our operations, we design future-ready solutions. Explore what makes us unique.
                        </Typography>
                    </CardContent>
                    <CardActions sx={{pl:0}}>
                        <Button variant='text' className='btn' disableFocusRipple disableRipple sx={{pl:0}} onClick={() => handleNavigate('our-approach')}>
                            <Typography variant='body2' component={'p'} className='normal-text' 
                            sx={{textTransform:'capitalize', fontFamily:'Inter',lineHeight:'1.8em', color:'#4F9A41 !important'}}>
                            Read More
                        </Typography>
                        <img src='/assets/images/right-arrow.png' alt='right icon' />
                        </Button>
                    </CardActions>
                </Grid>
                <Grid item xs={12} sm={4} md={5} sx={{mt: isMobile && '2em'}}>
                    <Typography variant='body2' component={'p'} className='normal-text size-20'>
                        OUR CULTURE
                    </Typography>
                    <Card sx={{mt:'1.2em', mb:'2em', borderRadius:0 }}>
                        <div style={{position:'relative', height:220}}>
                            <div className='img-wrapper'>
                                <img src='/assets/images/getimage2.jpg' alt='Blog' style={{width:'100%', height: isMobile && '100%'}}/>
                            </div>
                        </div>
                    </Card>
                    <CardContent sx={{pl:0, py:0, pr: isMobile && 0}}>
                        <Typography variant='h4' component={'h4'} sx={{minHeight:'3em', mb:2, fontFamily: 'Playfair Display',fontSize:'1.5em',fontWeight:500, lineHeight:'150%'}}>
                            Forging a deeper social connect
                        </Typography>
                        <Typography variant='body2' component={'p'} className='normal-text' sx={{minHeight:150}}>
                            Compassion is the driving force behind our actions. It guides us to shape lives and make meaningful interventions. As a socially responsible organization, we work to create a promising future for the community.
                        </Typography>
                    </CardContent>
                    <CardActions sx={{pl:0}}>
                        <Button variant='text' className='btn' disableFocusRipple disableRipple sx={{pl:0}} onClick={() => handleNavigate('careers')}>
                            <Typography variant='body2' component={'p'} className='normal-text'
                                sx={{textTransform:'capitalize', lineHeight:'1.8em', color:'#4F9A41 !important'}}>
                                Read More
                            </Typography>
                            <img src='/assets/images/right-arrow.png' alt='right icon' />
                            </Button>
                    </CardActions>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default GetUsKnow;