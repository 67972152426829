import React, { useState } from 'react';
import { Grid, Typography, Breadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import FootPrint from '../footprint';

const CaseStudy = (props) => {

    const caseStudyArr = [
        {
            title: "IBMS for Efficient Operations at AIIMS, Jammu",
            content: `The IBMS implemented at AIIMS Jammu Hospital integrates different building systems and offers centralized control, energy management, fault detection, automation, security integration, and real-time reporting. This sophisticated system enhances the efficiency, operational reliability, and security of the hospital while promoting sustainability and resource optimization. 
             <br /><br />Additionally, the AIIMS Jammu Hospital IBMS includes the integration and control of lifts within the facility. This ensures smooth and efficient vertical transportation of patients, staff and equipment. It also enables real-time monitoring of lift performance, and helps facility managers identify potential issues and take preventive measures to minimize downtime and disruptions. Integration of the systems enhances the overall functionality of the hospital and contributes to a seamless and reliable healthcare environment.`,
             subtitle: `System for operational reliability and resource optimization`,
            subtitleContent: `The IBMS system is designed to be scalable and adaptable to future expansion and technological advancements. It can accommodate additional systems and components as the facility grows, ensuring long-term viability and investment protection.`,
            subContent:[
                {subtitle: `Integrated Security Systems`, subtitlecontent: `Integration of security systems such as access control, CCTV surveillance and
                alarm systems ensures a comprehensive approach to hospital security, with centralized monitoring, real-time alerts, and seamless coordination between
                different security components.`},
                {subtitle: `Real-time reporting and analytics`, subtitlecontent: `Real-time reports and analytics on the performance, energy consumption, and
                other key metrics help to detect trends, potential issues, and make informed
                decisions regarding system optimization and resource allocation.`},
                {subtitle: `Occupancy monitoring`, subtitlecontent: `Occupancy sensors to monitor and regulate the use of different areas within the
                hospital. This data helps to optimize HVAC settings, lighting, and resource allocation,
                 improving energy efficiency and reducing operational costs.`},
                {subtitle: `Fault detection and diagnostics`, subtitlecontent: `Advanced algorithms and sensors detect and diagnose faults or anomalies in
                different building systems. This helps to identify issues early on, and facilitates proactive maintenance and troubleshooting, ensuring uninterrupted operation of critical systems.`}
            ],
            sustainTitle : `Sustainability Features of the Project`,
            sustainContent: [
                {title: `Energy Management`, content:`The IBMS include features to monitor and optimize energy consumption within the facility for reduction of operational costs and promotion of sustainability.`},
                {title: `Centralized monitoring and control`, content:`Centralized monitoring and control of various building systems, including HVAC, lighting, power distribution, security, fire safety, and water management.`},
                {title: `Intelligent automation`, content:`It incorporates intelligent automation capabilities for lighting systems, HVAC
                controls, and scheduling, thereby improving energy efficiency and people comfort. It enables the optimization of various parameters based on predefined criteria.`},
                
            ],
            ibmsTitle: `The solution implemented at AIIMS Jammu`,
            ibmsContent: [`Real-time building management`, `Failsafe security`, `Reliable healthcare environment`, `Fire alarms & fire safety`, `System scalability & flexibility`, `Integrated lift operation`]
        },
        {
            title: "Energy-efficient HVAC Solutions for Nalanda University, Bihar",
            content: `We are honored to have played a pivotal role in the HVAC project at Nalanda University, the first net zero university in India and a remarkable revival and transformation of the historic South East Asian learning center. 
                <br /> <br />
                Our commitment to delivering innovative MEP solutions with a focus on energy efficiency makes this project particularly noteworthy. In the implementation of this prestigious project, we prioritized energy conservation, sustainability aspects, and client satisfaction. 
                <br/> <br />
                Here, we present the key highlights of our integrated approach.`,
            subtitle: `HVAC solutions: Beyond conventional approaches`,
            subtitleContent: `At Nalanda University, we have incorporated multiple energy-conserving technologies, including the following`,
            subContent:[
                {subtitle: `Thermal Storage`, subtitlecontent: `Our thermal storage systems store excess cooling capacity for peak-demand periods. They effectively reduce energy consumption and costs.`},
                {subtitle: `Brine Chillers`, subtitlecontent: `We employ efficient heat transfer and cooling techniques through our brine chillers. They enhance the overall energy efficiency of the HVAC system.`},
                {subtitle: `Geothermal Water-Cooled VRV System`, subtitlecontent: `Harnessing geothermal energy, our water-cooled VRV system maximizes efficiency while minimizing the environmental impact.`},
                {subtitle: `Devap Cooling System`, subtitlecontent: `With this system, we achieve evaporative cooling, significantly reducing energy usage compared to traditional air-cooled solutions.`}
            ],
            sustainTitle : `Sustainability features of the project`,
            sustainContent: [
                {title: `Net-zero energy building`, content:`Our HVAC system design at Nalanda University is dedicated to achieving net-zero energy consumption, minimizing environmental impact, and maximizing energy efficiency.`},
                {title: `Energy efficiency `, content:`This installation ensures optimal comfort for the university's occupants while maximizing energy efficiency across the expansive campus. For this we have used the largest water-cooled VRV installation.`},
                {title: `Intelligent automation and SCADA`, content:`Our intelligent automation system, monitored through a Supervisory Control and Data Acquisition (SCADA) system. It optimizes energy consumption and overall HVAC performance.`},
                
            ],
            ibmsTitle: `The solution implemented at Nalanda University, Bihar `,
            ibmsContent: [`Diverse HVAC solutions`, `Devap cooling system`, `Brine chillers`, `Fire safety`, `Thermal storage`, `VRV installations`]
        },
        {
            title: "Integrating MEP Solutions for MOPA International Airport, Goa",
            content: `At the MOPA International Airport, situated in the picturesque state of Goa, the emphasis has been on efficiency and sustainability of MEP solutions to cater to the airport's diverse requirements. The efficient plumbing system and installations are in compliance with local plumbing codes, regulations, and safety standards to ensure safety and functionality. 
                <br /><br />
                The system has been specifically designed to help the airport qualify for LEED (Leadership in Energy and Environmental Design) points by promoting water conservation and efficiency. The fire fighting system  encompasses automatic sprinklers, fire extinguishers, hydrant systems, fire pumps, and a well laid-out emergency strategy. Additionally, we provide specialized training to airport staff and firefighters on the proper use of equipment and response procedures. Energy-efficient cooling solutions are integrated to minimize the airport's environmental footprint and support sustainable practices.`,
                subtitle: `Creating environmentally responsible infrastructure that is comfortable`,
                subtitleContent: `The MOPA International Airport Goa has enhanced fire safety features, rapid response strategies, and effective control and prevention of fire incidents built into its design. The innovative plumbing system with its focus on water efficiency contributes to a reliable, sustainable, and well-maintained plumbing system for the facility.`,
                subContent:[
                    {subtitle: `Efficient and effective fire prevention and control `, subtitlecontent: `Automatic sprinkler systems in critical areas, strategically placed sophisticated fire extinguishers for different classes: Class A, Class B and Class C fires. Also, fire hydrant system and pumps with adequate number of outlets for easy access to water supply during fire control operations.`},
                    {subtitle: `Comprehensive emergency response plan`, subtitlecontent: `Formulation of a comprehensive emergency plan ensuring a coordinated response in an emergency situation. Regular inspection and maintenance of fire fighting system ensures optimal performance and improved fire safety.`},
                    {subtitle: `Efficient drainage and sewage system`, subtitlecontent: `Proper disposal of waste water and sewage along with an  innovative system to drain rainwater from airport roofs, thus prevent ponding and reduce use of downpipes. This minimizes risk of water damage and ensures effective drainage.`},
                    {subtitle: `Backflow prevention measures`, subtitlecontent: `Features like check valves and air gaps to ensure contaminated water does not flow back into the potable water supply. Regular upkeep and prompt detection of issues in the plumbing system to minimize disruptions that may impact airport operations.`}
                ],
                sustainTitle : `Sustainability features of the project`,
                sustainContent: [
                    {title: `Promoting water efficiency`, content:`Efficient drainage and sewage systems, along with effective water conservation measures, backflow prevention devices, and compliance with regulations.`},
                    {title: `Reduced dependency on fresh-water sources `, content:`Condensate drain collection system to collect water that is a byproduct of air conditioning and HVAC systems. A specialized system to redirect the condensate to be reused for non-potable purposes.`},
                    {title: `Environmentally responsible infrastructure`, content:`The condensate drain collection system exemplifies environmentally responsible infrastructure that mitigates the environmental impact of water usage in the facility.`},
                    
                ],
                ibmsTitle: `The solution implemented at MOPA International Airport, Goa `,
                ibmsContent: [`Specially-designed emergency response plan`, `Compliance with industry standards for potable drinking water`, `Airport qualified for LEED (Leadership in Energy and Environmental Design) certification`, `Geberit Pluvia siphonic roof drainage system`, `Prevention of water cross-contamination`, `High-quality plumbing fixtures`]
        }
    ]
    const [caseStudy, setCaseStudy] = useState(0)

    const handleCaseStudy = (event) => {
        setCaseStudy(event.target.value);
        props.handleBanner(event.target.value);
    }


    return (
        <Grid item xs={12} sx={{width:'100%', display:'flex', justifyContent:'center', flexDirection:'column', alignItems:'center'}}>
            <Grid item sx={{px: !isMobile ? 10 : 2.5, width:'100%', maxWidth:1440, display:'flex', justifyContent:'center', pt:'2em'}}>
                <Grid item sx={{px: !isMobile && 12.5, pt:'1em', width:'100%'}}
                >
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to={'/'} className='normal-text title-color' style={{textDecoration:'none'}} >Home</Link>
                        <Typography variant='body2' component={'p'} className='normal-text' sx={{color:'#535353'}}>Case Study</Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Grid item sx={{px: isMobile && 2.5, backgroundColor:'#F3F3F3', width:'100%', display:'flex', justifyContent:'center', mt:'2em'}}>
                <Grid item sx={{px: !isMobile && 12.5, width:'100%', maxWidth:1440}}
                >
                    <Grid item xs={12} sx={{mt:'0em', px: !isMobile && 10 }}>
                        <Grid item xs={12} sx={{display:'flex', width:'100%',py:'1em', justifyContent:'flex-start', alignItems:'center'}}>
                            <Grid item xs={6} sm={3} >
                                <Typography className='normal-text' sx={{fontWeight:'500 !important', lineHeight:2}}>Select case study</Typography>
                            </Grid>
                            <Grid item xs={6} sm={4} sx={{borderLeft: '1px solid #B2C7D7'}}>
                                <select value={caseStudy} className="optionStyle" style={{width:'100%', maxWidth:250}} onChange={handleCaseStudy}>
                                    <option value={0}>AIIMS Jammu Hospital</option>
                                    <option value={1}>Nalanda University, Bihar</option>
                                    <option value={2}>MOPA International Airport, Goa</option>
                                </select>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item sx={{px: !isMobile ? 10 : 2.5, width:'100%', maxWidth:1440, display:'flex', justifyContent:'center', pt: !isMobile && '2em'}}>
                <Grid item sx={{px: !isMobile && 12.5, pt:'1em'}}
                >
                    <Grid item xs={12} sx={{pt:'2em', pb: !isMobile ? '2em' : '1em'}}>
                        <Typography className='titleStyle title-color' sx={{ fontSize:36, pb:2}}>{caseStudyArr[caseStudy].title}</Typography>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{display:'flex', flexFlow: isMobile && 'column'}}>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Typography variant='body2' component={'p'}  className='normal-text' sx={{mb:2, lineHeight:2}} dangerouslySetInnerHTML={{ __html:caseStudyArr[caseStudy].content }}>
                                
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} sx={{textAlign:'-webkit-center', pt:0, pb:0, ml: !isMobile? 2 : 0}}>
                                <Grid item xs={12} sx={{textAlign:'-webkit-center', background: !isMobile ? 'url(/assets/images/getknowbg.png) no-repeat' : 'url(/assets/images/getknowbgmobile.png) no-repeat' , width:'100%', maxWidth:525, height:'auto', maxHeight:396, backgroundPosition: !isMobile ? 'bottom left' : 'center', pt:!isMobile ? 6 : 2, pb: !isMobile ? 18: 2, backgroundSize: isMobile && 'contain' }}>
                                    <div style={{position:'relative', height:250}}>
                                        <div className='img-wrapper' style={{ display: isMobile && 'flex', justifyContent: isMobile && 'center', alignItems: isMobile && 'center'}}>
                                            <img src='/assets/images/brightercycle.png' alt='' style={{width:'45%', mixBlendMode: 'darken'}}/>
                                        </div>
                                    </div>
                                        {/* <div style={{background:'url(/assets/images/brightercycle.png) no-repeat', mixBlendMode: 'darken', width:235, height:243, transform: isMobile && 'scale(0.8)'}}></div> */}
                                </Grid>
                                
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{pt: !isMobile ? '2em' : '1em', pb:'1em'}}>
                        <Typography className='titleStyle title-color' sx={{ pb:2}}>{caseStudyArr[caseStudy].subtitle}</Typography>
                        <Typography className='normal-text' sx={{mb:2, lineHeight:2}} dangerouslySetInnerHTML={{__html: caseStudyArr[caseStudy].subtitleContent}}>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item sx={{px: !isMobile ? 10 : 2.5, width:'100%', maxWidth:1440, display:'flex', justifyContent:'center', pt:'0.5em'}}>
                <Grid item xs={12} sx={{display:'flex', px: !isMobile && 12.5,columnGap:'2em', rowGap:'1em', flexFlow: isMobile && 'column'}}>
                    <Grid item xs={12} sm={6} sx={{backgroundColor:'#D8F2FF', padding:'2em'}}>
                        <Typography className='normal-text' sx={{mb:1, fontWeight:'500 !important', lineHeight:2}}>
                            {caseStudyArr[caseStudy].subContent[0].subtitle}
                        </Typography>
                        <Typography className='normal-text' sx={{mb:1, lineHeight:2}} dangerouslySetInnerHTML={{__html: caseStudyArr[caseStudy].subContent[0].subtitlecontent}}>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{backgroundColor:'#D8F2FF', padding: !isMobile?'2em' : '2em 1em'}}>
                        <Typography className='normal-text' sx={{mb:1, fontWeight:'500 !important', lineHeight:2}}>
                        {caseStudyArr[caseStudy].subContent[1].subtitle}
                        </Typography>
                        <Typography className='normal-text' sx={{mb:1, lineHeight:2}} dangerouslySetInnerHTML={{__html: caseStudyArr[caseStudy].subContent[1].subtitlecontent}}>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item sx={{px:!isMobile ? 10 : 2.5, width:'100%', maxWidth:1440, display:'flex', justifyContent:'center', py:'2em', pb:'4em'}}>
                <Grid item xs={12} sx={{display:'flex', px: !isMobile && 12.5,columnGap:'2em', rowGap:'1em', flexFlow: isMobile && 'column'}}>
                    <Grid item xs={12} sm={6} sx={{backgroundColor:'#D8F2FF', padding:'2em'}}>
                        <Typography className='normal-text' sx={{mb:1, fontWeight:'500 !important', lineHeight:2}}>
                            {caseStudyArr[caseStudy].subContent[2].subtitle}
                        </Typography>
                        <Typography className='normal-text' sx={{mb:1, lineHeight:2}} dangerouslySetInnerHTML={{__html: caseStudyArr[caseStudy].subContent[2].subtitlecontent}}>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{backgroundColor:'#D8F2FF', padding:'2em'}}>
                        <Typography className='normal-text' sx={{mb:1, fontWeight:'500 !important', lineHeight:2}}>
                            {caseStudyArr[caseStudy].subContent[3].subtitle}
                        </Typography>
                        <Typography className='normal-text' sx={{mb:1, lineHeight:2}} dangerouslySetInnerHTML={{__html: caseStudyArr[caseStudy].subContent[3].subtitlecontent}}>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <FootPrint 
                title='Our projects' 
                content='We offer unique solutions for every project we undertake and our experts examine the details minutely. We also take pride in providing sustainable solutions that minimize our carbon footprint or impact on environment.' />
            <Grid item sx={{px: !isMobile ? 10 : 2.5, width:'100%', maxWidth:1440, display:'flex', justifyContent:'center', pt:'3em', pb: !isMobile ? '3em': '2em'}}>
                <Grid item xs={12} sx={{px: !isMobile && 12.5}}>
                    <Typography variant='body2' component='h5' className='titleStyle title-color' sx={{ pb:2}}>{caseStudyArr[caseStudy].sustainTitle}</Typography>
                    <Grid item xs={12} sx={{display:'flex', flexFlow: isMobile && 'column', columnGap:'1em',}}>
                        <Grid item xs={12} sm={2}>
                            <Typography variant='body2' component={'p'} className='normal-text' sx={{mb:1, fontWeight: '500 !important', lineHeight:2}}>
                                {caseStudyArr[caseStudy].sustainContent[0].title}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={10}>
                            <Typography variant='body2' component={'p'} className='normal-text' sx={{mb:1, lineHeight:2}}>
                                {caseStudyArr[caseStudy].sustainContent[0].content}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{display:'flex', flexFlow: isMobile && 'column',columnGap:'1em', mt:'0.5em'}}>
                        <Grid item xs={12} sm={2}>
                            <Typography variant='body2' component={'p'} className='normal-text' sx={{mb:1, fontWeight: '500 !important', lineHeight:2}}>
                            {caseStudyArr[caseStudy].sustainContent[1].title}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={10}>
                            <Typography variant='body2' component={'p'} className='normal-text' sx={{mb:1, lineHeight:2}}>
                                {caseStudyArr[caseStudy].sustainContent[1].content}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{display:'flex', flexFlow: isMobile && 'column', columnGap:'1em', mt:'0.5em'}}>
                        <Grid item xs={12} sm={2}>
                            <Typography className='normal-text' sx={{mb:1, fontWeight: '500 !important', lineHeight:2}}>
                                {caseStudyArr[caseStudy].sustainContent[2].title}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={10}>
                            <Typography className='normal-text' sx={{mb:1, lineHeight:2}}>
                                {caseStudyArr[caseStudy].sustainContent[2].content}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item sx={{width:'100%', backgroundColor:'#F4F8FA', mt:'2em', pt:'2em', pb:'4em', display:'flex', justifyContent:'center'}}>
                <Grid item sx={{background:!isMobile && 'url(/assets/images/ibmsbg.png) no-repeat', backgroundPosition:'center right', width:'100%', display:'flex', justifyContent:'center'}}>
                    <Grid item sx={{px:!isMobile ? 22.5 : 2.5, width:'100%', maxWidth:1440, display:'flex', justifyContent:'center', pt:'2em'}}>
                        <Grid item xs={12}>
                            <Typography className='titleStyle title-color' sx={{ pb:2, width:'80%'}}>{caseStudyArr[caseStudy].ibmsTitle}</Typography>
                            <Grid item xs={12} sx={{display:'flex', flexFlow: isMobile && 'column'}}>
                                <Grid item xs={12} sm={6}>
                                    <Grid item xs={12} sx={{display:'flex', mb:'1em', alignItems:'center'}}>
                                        <img src='/assets/images/featured-icon1.png' alt='featured-icon1' />
                                        <Typography className='normal-text' sx={{ml:'1em'}}>
                                            {caseStudyArr[caseStudy].ibmsContent[0]}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{display:'flex', mb:'1em', alignItems:'center'}}>
                                        <img src='/assets/images/featured-icon2.png' alt='featured-icon1' />
                                        <Typography className='normal-text' sx={{ml:'1em'}}>
                                            {caseStudyArr[caseStudy].ibmsContent[1]}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{display:'flex', mb:'1em', alignItems:'center'}}>
                                        <img src='/assets/images/featured-icon3.png' alt='featured-icon1' />
                                        <Typography className='normal-text' sx={{ml:'1em'}}>
                                            {caseStudyArr[caseStudy].ibmsContent[2]}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Grid item xs={12} sx={{display:'flex', mb:'1em', alignItems:'center'}}>
                                        <img src='/assets/images/featured-icon4.png' alt='featured-icon1' />
                                        <Typography className='normal-text' sx={{ml:'1em'}}>
                                            {caseStudyArr[caseStudy].ibmsContent[3]}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{display:'flex', mb:'1em', alignItems:'center'}}>
                                        <img src='/assets/images/featured-icon5.png' alt='featured-icon1' />
                                        <Typography className='normal-text' sx={{ml:'1em'}}>
                                            {caseStudyArr[caseStudy].ibmsContent[4]}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{display:'flex', mb:'1em', alignItems:'center'}}>
                                        <img src='/assets/images/featured-icon6.png' alt='featured-icon1' />
                                        <Typography className='normal-text' sx={{ml:'1em'}}>
                                            {caseStudyArr[caseStudy].ibmsContent[5]}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )

}

export default CaseStudy;