import React from 'react';
import './App.css';
import './number.css';

import Header from './components/header';
import { Route, Routes } from 'react-router-dom';
import Footer from './components/footer';
import Home from './pages/home';
import AboutUs from './pages/aboutus';
import Contact from './pages/contactus';
import CareerPage from './pages/career';
import Corporate from './pages/corporatecompliance';
import Services from './pages/sevices';
import Products from './pages/products';
import Projects from './pages/projects';
import CaseStudy from './pages/casestudy';
import Leadership from './pages/leadership';
import OurApproach from './pages/ourapproach';
import SocialCommitment from './pages/socialcommitment'
import ApplyOnline from './pages/career/applyonline';
import Privacy from './pages/privacy';


const App=() => {

const elementRoute = <Routes>
  <Route path={'/'} exact element={<Home />} />
  <Route path={'/about'} element={<AboutUs />} />
  <Route path={'/applyonline/:name'} element={<ApplyOnline />} />
  <Route path={'/case-study'} element={<CaseStudy />} />
  <Route path={'/careers'} element={<CareerPage />} />
  <Route path={'/contact'} element={<Contact />} />
  <Route path={'/corporate-compliance'} element={<Corporate />} />
  <Route path={'/services/:id'} element={<Services />} />
  <Route path={'/services/'} element={<Services />} />
  <Route path={'/projects'} element={<Projects />} />
  <Route path={'/products'} element={<Products />} />
  <Route path={'/our-approach'} element={<OurApproach />} />
  <Route path={'/leadership'} element={<Leadership />} />
  <Route path={'/privacy-policy'} element={<Privacy />} />

  <Route path={'/social-commitment'} element={<SocialCommitment />} />
</Routes>


  return (
    <div className="App">
      <Header />
      {elementRoute}
      <Footer />
    </div>
  );
}

export default App;
