import React, { useState } from 'react'
import {IconButton, Paper, ClickAwayListener, Grow, Popper} from '@mui/material'
import { Link } from 'react-router-dom';
// import MenuIcon from '@mui/icons-material/Menu';

const MobileHeaderMenu = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const linkStyle={ width: '100%', marginBottom:'1em', textDecoration:'none'}
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setMenuOpen((prevOpen) => !prevOpen);
      };

      const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
          return;
        }
    
        setMenuOpen(false);
      };
    
      const prevOpen = React.useRef(menuOpen);
      React.useEffect(() => {
        if (prevOpen.current === true && menuOpen === false) {
          anchorRef.current.focus();
        }
    
        prevOpen.current = menuOpen;
      }, [menuOpen]);
    return (
        <>
        <IconButton
            ref={anchorRef}
            style={{
              width:50, height:45, backgroundColor:'transparent', borderRadius:10, paddingRight:0, paddingTop:0
            }}
          id="composition-button"
          aria-controls={menuOpen ? 'composition-menu' : undefined}
          aria-expanded={menuOpen ? 'true' : undefined}
          aria-haspopup="true"
        onClick={() => handleToggle()}>
            <div class= {!menuOpen ? 'icon' : 'icon iconOpened'}>
              <span class= {!menuOpen ? 'menu-icon' : 'menu-icon menu-iconOpened'}></span>
            </div>
          {/* <MenuIcon sx={{fontSize:'1.8em', float:'right'}} /> */}
          </IconButton>
        {
            <Popper
                open={menuOpen}
                anchorEl={anchorRef.current}
                role={undefined}
                style={{zIndex:1, width:'100%'}}
                placement="bottom-start"
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
            
            <Grow
              {...TransitionProps}
              style={{
                marginTop:10,
                
                transformOrigin:
                  placement === 'bottom-start' ? 'top' : 'top',
              }}
            >
            <Paper >
                
            <ClickAwayListener onClickAway={handleClose}>
                <div style={{width:'auto', maxWidth: '100%', display:'flex', justifyContent:'center', textAlign:'left', padding:'1em'}}>
                  <div style={{width:'50%', display:'flex', flexFlow:'column'}}>
                  <Link to={"/services"} className='link-color' style={linkStyle} onClick={handleClose} >Services</Link>
                  <Link to='/services/hvac' className='link-color' style={linkStyle} onClick={handleClose}>HVAC</Link>
                  <Link to='/services/plumbing' className='link-color' style={linkStyle} onClick={handleClose}>Plumbing</Link>
                  <Link to='/services/ibms' className='link-color' style={linkStyle} onClick={handleClose}>IBMS / LV</Link>
                  <Link to='/services/firefighting' className='link-color' style={linkStyle} onClick={handleClose}>Firefighting</Link>
                  <Link to='/services/electrical' className='link-color' style={linkStyle} onClick={handleClose}>Electrical</Link>
                  <Link to={"/projects"} className='link-color' style={linkStyle} onClick={handleClose} >Projects</Link>
                  <Link to={"/products"} className='link-color' style={linkStyle} onClick={handleClose} >Products</Link>
                  </div>
                  <div style={{width:"50%", display:'flex', flexFlow:'column'}}>
                    <Link to={"/about"} className='link-color' style={linkStyle} onClick={handleClose}>About Us</Link>
                    <Link to='/our-approach' className='link-color' style={linkStyle} onClick={handleClose}>Our Approach</Link>
                    <Link to='/leadership' className='link-color' style={linkStyle} onClick={handleClose}>Leadership</Link>
                    <Link to='/careers' className='link-color' style={linkStyle} onClick={handleClose}>Careers</Link>
                    <Link to='/social-commitment' className='link-color' style={ linkStyle } onClick={handleClose}>Social Commitment</Link>
                    <Link to={"/contact"} className='link-color' style={linkStyle} onClick={handleClose}>Contact Us</Link>
                  </div>
                </div>
            </ClickAwayListener>
            </Paper>
            </Grow>
            )}
            </Popper>
        }
        </>
    )

}

export default MobileHeaderMenu;